
import { Vue, Component } from 'vue-property-decorator';

import $app from '@/plugins/modules';
import { Common } from '@/common';
import { System } from '@/modules/system';
import { Clinic } from '@/modules/clinic'
import { IExternalMessage, IExternalMessageTemplate, IMessagesFilter, IMessagesSearch } from '../types';
import { ITableHeader, IPagination } from '../../system/types';

@Component
export default class MessagesView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  clinic = $app.module(Clinic);

  loading = false;
  showGroups = false;

  showGroupEdit = false;
  group: IExternalMessageTemplate | null = null;

  groups: Array<IExternalMessageTemplate> = [];
  filter: IMessagesFilter = $app.clone(this.clinic.$store.MessagesSearch.filter);

  showItem = false;
  processItem = false;
  selectedItem: IExternalMessage | null = null;

  get headers(): Array<ITableHeader> {
    return [
      {
        text: '',
        value: 'messageState',
        align: 'left',
        width: '5%'
      },
      {
        text: $app.i18n('dplus.messages.MessagePlanned'),
        value: 'messagePlanned',
        align: 'left',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.customers.PatientName'),
        value: 'messagePatient',
        align: 'left',
        width: '30%'
      },
      {
        text: $app.i18n('dplus.customers.Edit.PatientPhone'),
        value: 'messagePhone',
        align: 'left',
        width: '15%'
      },
      {
        text: $app.i18n('dplus.messages.MessageText'),
        value: 'messageText',
        align: 'left',
        width: '40%'
      }
    ];
  }

  get search(): IMessagesSearch {
    return this.clinic.$store.MessagesSearch;
  }

  async findNext(pagination: IPagination) {
    if (!this.loading) {
      this.loading = true;
      await this.clinic.$store.findMessages(pagination);
      this.loading = false;
    }
  }

  async findFirst() {
    this.loading = false;
    const request = this.system.$module.newSearch(this.clinic.$store.MessagesSearch, this.filter);
    this.clinic.$store.setMessagesSearch({
      items: [],
      filter: request
    });
    this.findNext(request);
  }

  async store(item: IExternalMessage) {
    this.processItem = true;
    try {
      await this.clinic.$store.storeMessage(item);
      this.showItem = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async open(item: IExternalMessage) {
    this.selectedItem = item
    this.showItem = await this.reloadGroups()
  }

  async openGroups() {
    this.showGroups = await this.reloadGroups()
  }

  async reloadGroups(): Promise<boolean> {
    let result = false;
    this.loading = true;
    try {
      const list: Array<IExternalMessageTemplate> = await $app.get('/api/ex-messages/templates');
      list.forEach(g => { g.processing = false })
      this.groups = list
      result = true;
    } catch (error) {
      $app.pushError(error)
    }
    this.loading = false;
    return result;
  }

  openGroup(item: IExternalMessageTemplate) {
    this.group = item;
    this.showGroupEdit = true;
  }

  async addGroup() {
    const template: IExternalMessageTemplate = {
      code: '',
      title: {},
      text: {},
      processing: false
    }

    this.common.$store.appLanguages.forEach(l => {
      template.title[l.code] = ''
      template.text[l.code] = ''
    })

    this.group = template
    this.showGroupEdit = true
  }

  async storeGroup(item: IExternalMessageTemplate) {
    item.processing = true
    try {
      await $app.post('/api/ex-messages/templates', item);
      const listItem = this.groups.find(g => g.code === item.code)
      if (listItem) {
        listItem.title = item.title
        listItem.text = item.text
      } else {
        this.groups.push(item)
      }
      this.showGroupEdit = false;
    } catch (error) {
      $app.pushError(error)
    }
    item.processing = false
  }

  async removeGroup(item: IExternalMessageTemplate) {
    item.processing = true
    try {
      await $app.delete('/api/ex-messages/templates/' + item.code);
      this.groups = this.groups.filter(g => g.code !== item.code)
    } catch (error) {
      $app.pushError(error)
    }
    item.processing = false
  }

  mounted() {
    if (this.search.filter.totals === 0) {
      this.findFirst();
    }
  }
}
