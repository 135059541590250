import { AppModule, VuexModuleConstructor } from '@/common/types'
import ClinicStore from './ClinicStore'
import ClinicModule from './ClinicModule'
import { IExternalMessage } from './types'
import $app from '@/plugins/modules'
export class Clinic extends AppModule<ClinicStore, ClinicModule> {
  public get name(): string {
    return 'clinic'
  }

  protected get storeClass(): VuexModuleConstructor<ClinicStore> {
    return ClinicStore
  }
}

export function processMessageTemplate(template: string, message: IExternalMessage): string {
  let value = template
  if (message) {
    value = value.replaceAll('%FirstName%', message.patientFirstName || '')
    value = value.replaceAll('%LastName%', message.patientLastName || '')
    value = value.replaceAll('%VisitDate%', (message.visitTimeFrom ? $app.date(message.visitTimeFrom) : ''))
    value = value.replaceAll('%VisitTime%', (message.visitTimeFrom ? $app.time(message.visitTimeFrom) : ''))
    value = value.replaceAll('%Doctor%', message.doctorName || '')
  }
  return value
}

export default ClinicModule
